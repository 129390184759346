import React, { FC } from "react";
import { plusIcon } from "../../images/svgIcons";
interface IElement {
  src: string;
  title: string;
  list: string[];
  reverse?: boolean;
  width?: boolean;
}
const UseCaseElement: FC<IElement> = ({ src, title, list, reverse, width }) => {
  const img = require(`../../images/use-cases/${src}`);
  return (
    <div
      className={`row w-100 mt-3 mx-auto ${reverse ? "flex-row-reverse" : ""} ${
        width ? "align-items-center" : ""
      }`}
    >
      <div className="col-12 col-md-6 use-case-text">
        <h3 className="f_size_20 l_height30">{title}</h3>
        <div className="l_height30">
          {list.map((item: string, index: number) => (
            <>
              <span className="f_size_14">{item}</span>
              {index !== list.length - 1 && (
                <span className="mx-2">{plusIcon}</span>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-center">
        <img
          src={img}
          alt="logos"
          style={{ maxWidth: width ? "230px" : "320px" }}
        />
      </div>
    </div>
  );
};

export default UseCaseElement;
