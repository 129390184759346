import React from 'react';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/mediaKitTabs';
import './styles.scss';
import UseCaseElement from '../../components/use-cases-element';
import SubscriptionContent from '../../components/subscriptionModal/subscription-content';

import eCommerce from '../../images/use-cases/eCommerce.png';
import investmentResearch from '../../images/use-cases/investment-research.png';
import marketingTeams from '../../images/use-cases/marketing-teams.png';
import sales from '../../images/use-cases/sales-teams.png';

import ImageWithTitle from './image-with-title';

const UseCases = () => {
  return (
    <Layout>
      <PageHero
        title="Growth hacking use cases using Hexomatic"
        subtitle="Automate time consuming tasks, tap into new markets, find more prospects and scale your business on autopilot with Hexomatic."
        titleSmall
      />
      <Section about>
        <Tabs />
        <div className="use-cases-page">
          <div className="row py-md-5">
            <ImageWithTitle
              src={eCommerce}
              alt={'eCommerce'}
              header={'Hexomatic for eCommerce'}
              subHeader={
                'Data is a massive advantage for eCommerce businesses. Hexomatic can help you turn any website to a spreadsheet or API to grow your business and delegate time consuming tasks.'
              }
            />
            <UseCaseElement
              title="Monitor competitor pricing"
              list={['Scraping recipe', 'Google Sheets']}
              src="1.png"
              width
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Collect product descriptions and images at scale"
              list={['Scraping recipe', 'CSV export']}
              src="2.png"
              reverse
              width
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Collect customer reviews from competitors"
              list={['XML extractor', 'Scraping recipe', 'Schema scraper', 'CSV export']}
              src="3.png"
            />
          </div>
          <div className="row py-md-5">
            <ImageWithTitle
              src={sales}
              alt={'graph'}
              header={'Hexomatic for sales teams'}
              subHeader={
                'If your business sells to other businesses then getting fresh, targeted leads is the fuel for your rocket ship. Find relevant prospects for just about every niche or industry on autopilot with Hexomatic.'
              }
            />

            <UseCaseElement
              title="Find fresh prospects using Google search "
              list={[
                'Google search automation',
                'Email scraper',
                'Social media scraper',
                'Tech stack discovery',
                'Google Sheets',
              ]}
              src="4.png"
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Find fresh prospects scraping conference attendees 
              &amp; sponsors"
              list={['Scraping recipe', 'Email scraper', 'Social media scraper', 'SEO meta tags', 'CSV']}
              src="5.png"
              reverse
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Find local businesses using Google Maps"
              list={[
                'Google maps automation',
                'Email scraper',
                'Social media scraper',
                'Tech stack discovery',
                'Google Sheets',
              ]}
              src="6.png"
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Scrape directories to find prospects in any niche"
              list={['Scraping recipe', 'Email scraper', 'Social media scraper', 'CSV']}
              src="7.png"
              reverse
            />
          </div>
          <div className="row py-md-5">
            <ImageWithTitle
              src={marketingTeams}
              alt={'web brouser'}
              header={'Hexomatic for marketing teams'}
              subHeader={
                'If you’re a marketer, SEO or growth hacker you know that growth is all about tapping into existing data, networks and opportunities.'
              }
            />
            
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Detect the tech stack of any website at scale"
              list={['CSV', 'Tech stack discovery']}
              src="9.png"
              width
              reverse
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Get the WHOIS details &amp; domain expiry of domains 
              in bulk"
              list={['CSV', 'WHOIS scanner', 'CSV']}
              src="10.png"
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Scrape SEO tags for a list of URLS"
              list={['CSV', 'SEO meta tags', 'Google Sheets']}
              src="11.png"
              reverse
            />
          </div>

          <div className="row py-md-5">
            <ImageWithTitle
              reverse
              src={investmentResearch}
              alt={'money calculator dollar symbol'}
              header={'Hexomatic for finance or investment research teams'}
              subHeader={
                'Get an unfair advantage by tapping into public data and automating time consuming research tasks.'
              }
            />

            <UseCaseElement
              title="Scrape stock market prices"
              list={['CSV', 'Scraping recipe', 'Google Sheets']}
              src="12.png"
              reverse
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Scrape currency prices"
              list={['CSV', 'Currency automation', 'Google Sheets']}
              src="13.png"
            />
            <div className="custom-hr my-4"></div>

            <UseCaseElement
              title="Scrape cryptocurrency prices"
              list={['CSV', 'Crypto automation', 'Google Sheets']}
              src="14.png"
              reverse
            />
          </div>

          <div className="row mb-5">
            <div className="col-12 text-center mb-4">
              <h2 className="f_size_22 f_700 t_color3 l_height40 mt_20 mb_20" style={{marginLeft: '15px'}}>
                Get our best growth hacking and automation strategies
              </h2>
              <h4 className="f_size_18 f_400  t_color3 l_height28 mt_20 mb_20" style={{marginLeft: '15px'}}>
                To help you automate and scale your business we have created a feature packed growth hacking playbook.
              </h4>
            </div>
            <div className="subscription-wrapper">
              <SubscriptionContent />
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};
export default UseCases;
